// Notes on CodeSandBox
// if you don't see a preview when you load this page for the first time, reload the browser tab to the right

import { createViewport, createSession } from "@shapediver/viewer";
import * as $ from 'jquery';

(async () => {
  // create a viewport
  const viewport = await createViewport({
    canvas: document.getElementById("canvas") as HTMLCanvasElement,
    id: "myViewport"
  });

  // create a session
  const session = await createSession({
    ticket:
      "964f31425ad4670639b0877f144b82c79bc1f20178391378fa6695ae76081aa50d06a8933ed7e65e3ef64020f12c92c65ffe552fc852da1b33fc90a03fc32e3970203e021ee42c112e3470ca7b2d586af98f22658fd6ee9deb2a3c1818fc22bacd0b15386ca9dc-2bb19a50f450ad9e2126e433694cd485",
    modelViewUrl: "https://sdeuc1.eu-central-1.shapediver.com",
    id: "mySession"
  });

  const globalDiv = <HTMLDivElement>document.getElementById('session');
  const params = Object.values(session.parameters);
  let x: number;
  let y: number;
  let z: number;
  let box_color: string;
  let shine;
  let transparency;
  for(let i = 0, il = params.length; i<il; i++){
    console.log(params[i]);
    const paramObject = params[i];
    
    console.log(paramObject.name);
    console.log(paramObject.id);
    console.log(paramObject.value);
 if(paramObject.name === "Shine"){
   shine = paramObject.value;
 }
 else if(paramObject.name === "Transparency"){
    transparency = paramObject.value;
 }
    const paramDiv = document.createElement("div");
    const label = document.createElement("label");
    label.setAttribute("for", paramObject.id);
    label.innerHTML = paramObject.name;

}
let send_data: Object;
let vars = {
    Shine: shine,
    Transparency: transparency
}
const url = "https://jgreen.antiquegardentractors.com/api.php";

try{
    send_data = $.ajax({
    url: url,
    type: 'POST',
    data: vars
});
    console.log("Results " + send_data);
}catch(error){
    console.log("Error "+ error);
}


})();